<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar gestor
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-form @submit.prevent="buscar()">
          <v-row class="mx-4 mt-0 mb-3">
            <v-col cols="12" md="6" class="py-1">
              Nombre
              <v-text-field
                v-model.trim="nombre"
                hide-details
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-end align-end py-1">
              <v-btn
                color="info"
                type="submit"
                :loading="load"
              >
                <v-icon left>fas fa-search</v-icon>
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-data-table
          v-model="selected"
          checkbox-color="primary"
          item-key="codigo"
          :headers="headers"
          :items="gestores"
          :loading="load"
          single-select
          show-select
          dense
        >
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          :disabled="selected.length != 1"
          @click="seleccionar()"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para buscar empresas por razon social. Cuando selecciona una emite la accion @seleccion
 *  al componente padre con el cuit y nombre de la empresa seleccionada
 *  tambien permite modificar el cuit o razon social de una emrpesa
 */
export default {
  data () {
    return {
      load: false,
      nombre: '',
      selected: [],
      gestores: [],
      headers: [
        { text: 'Código', value: 'codigo' },
        { text: 'Nombre', value: 'nombre' }
      ]
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async buscar () {
      if (!this.nombre) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar un nombre',
          color: 'warning'
        })
        return
      }
      this.load = true
      this.selected = []
      this.gestores = []
      await this.$store.dispatch('movBancarios/get_gestores', { nombre: this.nombre })
        .then((res) => {
          this.gestores = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    seleccionar () {
      this.$emit('seleccion', this.selected[0])
      this.dialog = false
    },
    clear () {
      this.nombre = ''
      this.selected = []
      this.gestores = []
    }
  }
}
</script>